import { useEffect, useState } from "react";
import { useRunWorkflow, useWorkflows } from "../api/workflow";
import classes from "./Workflow.module.scss";
import {
  FormControl,
  Select,
  MenuItem,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

type WorkflowProps = {
  connectionId: string;
  email: string;
  closeWorkflows: () => void;
};

export default function Workflow({
  connectionId,
  email,
  closeWorkflows,
}: WorkflowProps) {
  const { data: workflowsDetails, isLoading: loadingWorkflowsDetails } =
    useWorkflows(email);
  const { mutate: runWorkflow, isLoading: runningWorkflow } =
    useRunWorkflow(email);
  const [workflows, setWorkflows] = useState<any>([]);
  const [selectedWorlflow, setSelectedWorlflow] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (workflowsDetails?.data) {
      setWorkflows(workflowsDetails.data);
    }
  }, [workflowsDetails]);

  const onRunClick = () => {
    if (selectedWorlflow) {
      const request = {
        workflowId: selectedWorlflow,
        connectionId: connectionId,
      };
      runWorkflow(request as any, {
        onSuccess: (response: any) => {
            navigate(`/chat?chatId=${response.data.id}&email=${email}&connectionId=${connectionId}`);
            closeWorkflows();
        },
      });
    }
  };
  return (
    <div className={classes.root}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingWorkflowsDetails || runningWorkflow}
      >
        <CircularProgress sx={{ color: "#4B0082" }} />
      </Backdrop>
      <div className={classes.title}>Workflow</div>
      <div className={classes.content}>
        <div className={classes.select}>
          <div className={classes.optionsTitle}>Select workflow</div>
          <FormControl size="small" sx={{ flex: 1 }}>
            <Select
              id="workflow-select"
              value={selectedWorlflow}
              onChange={(e) => setSelectedWorlflow(e.target.value)}
            >
              {workflows?.map((workflow: any) => (
                <MenuItem key={workflow.id} value={workflow.id}>
                  {workflow.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={classes.footer}>
          <Button className={classes.run} onClick={onRunClick}>
            Run
          </Button>
          <Button className={classes.cancel} onClick={closeWorkflows}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
