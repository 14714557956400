import { useMutation, useQuery } from "react-query";
import { queryClient } from "../..";
import { useAuth0 } from "@auth0/auth0-react";
import { BE_SERVER } from "../utils/constant";
import { useSnackbar } from "notistack";

export const useConnection = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["connections", email], async () => {
    const token = await getAccessTokenSilently();
    const res = await fetch(`${BE_SERVER}/api/connections?email=${email}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!res.ok) {
      throw new Error("Error while fetching connections");
    }

    return res.json();
  });
};

export const useConnectionById = (email: string, id: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["connection", id], async () => {
    const token = await getAccessTokenSilently();
    const res = await fetch(
      `${BE_SERVER}/api/connections/${id}?email=${email}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!res.ok) {
      throw new Error("Error while fetching connection");
    }

    return res.json();
  });
};

export const useAddConnection = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (newConnection: any) => {
      const token = await getAccessTokenSilently();
      const res = await fetch(
        `${BE_SERVER}/api/connections/${newConnection.id}?email=${email}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(newConnection),
        }
      );

      if (!res.ok) {
        throw new Error("Error while adding connection");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["connections", email]);
        enqueueSnackbar("Connection added successfully", {
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar("Error while adding connection", { variant: "error" });
      },
    }
  );
};

export const useModifyConnection = (email: string, id: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (request) => {
      const token = await getAccessTokenSilently();
      const res = await fetch(
        `${BE_SERVER}/api/connections/${id}?email=${email}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(request),
        }
      );

      if (!res.ok) {
        throw new Error("Error while adding connection");
      }

      return res.text();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["connection", id]);
        queryClient.invalidateQueries(["connections", email]);
        enqueueSnackbar("Updated successfully", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Error while updating", { variant: "error" });
      },
    }
  );
};

export const useDeleteConnection = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (id) => {
      const token = await getAccessTokenSilently();
      const res = await fetch(
        `${BE_SERVER}/api/connections/${id}/fileChat?email=${email}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!res.ok) {
        throw new Error("Error while adding connection");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["connections", email]);
        queryClient.invalidateQueries(["chatHistory", email]);
        enqueueSnackbar("Connection deleted successfully", {
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar("Error while deleting connection", {
          variant: "error",
        });
      },
    }
  );
};

export const useConnectionMetadata = (email: string, id: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["connection-metadata", id], async () => {
    const token = await getAccessTokenSilently();
    const res = await fetch(
      `${BE_SERVER}/api/connections/metadata/${id}?email=${email}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!res.ok) {
      throw new Error("Error while fetching connection metadata");
    }

    return res.json();
  });
};

export const useUpdateConnectionMetadata = (email: string, id: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (newConnectionMetadata) => {
      const token = await getAccessTokenSilently();
      const res = await fetch(
        `${BE_SERVER}/api/connections/metadata/${id}?email=${email}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(newConnectionMetadata),
        }
      );

      if (!res.ok) {
        throw new Error("Error while adding connection");
      }

      return res.text();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["connection-metadata", id]);
        enqueueSnackbar("Metadata updated successfully", {
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar("Error while updating metadata", { variant: "error" });
      },
    }
  );
};

export const useRegenerateMetadata = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (id) => {
      const token = await getAccessTokenSilently();
      const res = await fetch(
        `${BE_SERVER}/api/connections/metadata/${id}?email=${email}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!res.ok) {
        throw new Error("Error while syncing metadata");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        enqueueSnackbar("Metadata regenerate initiated", {
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar("Error while initiating request", { variant: "error" });
      },
    }
  );
};

export const useRunQuery = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useMutation(async (request: any) => {
    const token = await getAccessTokenSilently();
    const res = await fetch(
      `${BE_SERVER}/api/connections/${request.connectionId}/query/run?email=${email}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(request),
      }
    );

    if (!res.ok) {
      throw new Error("Error while adding connection");
    }

    return res.json();
  });
};
