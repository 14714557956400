import {
  Avatar,
  Backdrop,
  Button,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Dialog,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import classes from "./Chat.module.scss";
import { useEffect, useRef, useState } from "react";
import {
  AttachFileRounded,
  AutoAwesomeMosaicRounded,
  AutoAwesomeOutlined,
  AutoAwesomeRounded,
  CloseRounded,
  ExpandMoreRounded,
  FileDownloadOutlined,
  FileDownloadRounded,
  LinkRounded,
  SendRounded,
} from "@mui/icons-material";
import Upload from "../utils/Upload";
import csvIcon from "../../icons/csv.svg";
import pdfExport from "../../icons/pdf_export.svg";
import { useUploadGSheet, useDownloadFile } from "../api/file";
import useQueryParameter, { stringAvatar } from "../utils/util";
import { useAddChat, useChatDetails, useNewFileChat } from "../api/chat";
import { v4 } from "uuid";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";
import botLogo from "../../icons/bot.png";
import { useConnection } from "../api/connections";
import OpenAI from "openai";
import hljs from "highlight.js/lib/common";
import "highlight.js/styles/atom-one-dark.css";
import { useLocation, useNavigate } from "react-router-dom";
import { savePDF } from "@progress/kendo-react-pdf";
import SheetUpload from "../utils/SheetUpload";
import { FileTypes } from "../utils/constant";
import FileViewer from "../files/FileViewer";
import { useCreateWorkflow } from "../api/workflow";
import { dotWave, trio } from 'ldrs'
import { WorkflowEdit } from "../workflow/WorkflowView";
dotWave.register();
trio.register();

export default function Chat() {
  const email = useQueryParameter("email");
  const newChat = useQueryParameter("new");
  const chatId = useQueryParameter("chatId");
  const connectionId = useQueryParameter("connectionId");
  const [currentChatId, setCurrentChatId] = useState(chatId || v4());
  const [currentThreadId, setCurrentThreadId] = useState("");
  const [currentText, setCurrentText] = useState("");
  const [openUpload, setOpenUpload] = useState(false);
  const [openSheetUpload, setOpenSheetUpload] = useState(false);
  const [droppedFile, setDroppedFile] = useState<any>(null);
  const [currentConnection, setCurrentConnection] = useState<any>(null);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [openWorkFlowDialog, setOpenWorkFlowDialog] = useState(false);
  const [workflowCreating, setWorkflowCreating] = useState(false);
  const [currentWorkflow, setCurrentWorkflow] = useState<any>(null);
  const [chats, setChats] = useState<any>([]);
  const { mutate: uploadFile, isLoading: uploadingFile } =
    useNewFileChat(email);
  const { mutate: addChat, isLoading: addingChat } = useAddChat(email);
  const { data: connections, isLoading: loadingConnections } =
    useConnection(email);
  const { mutate: createWorkflow, isLoading: creatingWorkflow } =
    useCreateWorkflow(email);

  const chatRef = useRef<HTMLDivElement>(null);
  const { mutate: uploadSheet, isLoading: uploadingSheet } =
    useUploadGSheet(email);

  const {
    data: chatDetails,
    refetch,
    isLoading: loadingChatDetails,
  } = useChatDetails(chatId, email);

  const openai = new OpenAI({
    apiKey: process.env.REACT_APP_OPENAI_API_KEY || "",
    dangerouslyAllowBrowser: true,
  });

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (connections?.data) {
      const connection = connections?.data?.find(
        (c: any) => c?.id === connectionId
      );
      setCurrentConnection(connection);
    }
  }, [connections, connectionId]);

  useEffect(() => {
    if (newChat === "true") {
      setCurrentChatId(v4());
      setCurrentConnection(null);
      setCurrentThreadId("");
      setDroppedFile(null);
      setChats([]);
    }
  }, [newChat]);

  useEffect(() => {
    if (chatId !== "") {
      setCurrentChatId(chatId);
      refetch();
    }
  }, [chatId]);

  useEffect(() => {
    if (chatDetails?.data?.messages) {
      modifyChats({ data: chatDetails.data.messages });
    }
  }, [chatDetails]);

  const handlePreviewModal = () => {
    setIsPreviewModalOpen((isPreviewModalOpen) => !isPreviewModalOpen);
  };

  const exportPDF = () => {
    if (chatRef.current) {
      savePDF(chatRef.current, {
        paperSize: "Letter",
        scale: 0.7,
        landscape: true,
        keepTogether: ".code",
      });
    }
  };

  const onFileDrop = (acceptedFiles: any) => {
    if (acceptedFiles?.length > 0) {
      setDroppedFile(acceptedFiles[0]);
    }
    setOpenUpload(false);
  };

  function downloadFile(url: any, name: any) {
    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = name;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    window.URL.revokeObjectURL(url);
  }

  const modifyChats = async (response: any) => {
    if (!response?.data) return;

    const fetchFileContent = async (fileId: any) => {
      try {
        const fileMetadata = await openai.files.retrieve(fileId);
        const res = await openai.files.content(fileId);
        const blob = await res.blob();
        return {
          url: window.URL.createObjectURL(blob),
          filename: fileMetadata.filename,
          displayName:
            fileMetadata?.filename?.split("/")?.pop() || fileMetadata?.filename,
        };
      } catch (error) {
        console.error("Error fetching file:", error);
        return null;
      }
    };

    const newChats = await Promise.all(
      response?.data?.map(async (chat: any) => {
        // Handle file_ids
        const filesUrls = await Promise.all(
          (chat.file_ids || []).map(fetchFileContent)
        );

        chat.files = filesUrls;

        // Handle content
        await Promise.all(
          (chat.content || []).map(async (content: any) => {
            if (content?.type === "image_file") {
              const fileObj = await fetchFileContent(
                content.image_file.file_id
              );
              if (fileObj) content.image_file.url = fileObj.url;
            }
          })
        );

        return chat;
      })
    );

    setChats(newChats);
    if (response.data.length > 0) {
      setCurrentThreadId(response.data[0].thread_id);
    }
  };

  const onSubmit = () => {
    const newConnectionId = v4();
    const request = {
      chat: JSON.stringify({
        chatId: currentChatId,
        newUpload: true,
        connectionId: newConnectionId,
        question: currentText,
        fileType: droppedFile.type,
      }),
      file: droppedFile,
    };

    uploadFile(request, {
      onSuccess: (response) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("connectionId", newConnectionId);
        navigate({
          pathname: location.pathname,
          search: searchParams.toString(),
        });
        modifyChats({ data: response.data.messages });
        setCurrentText("");
      },
    });
  };

  const onCreateWorkflow = () => {
    const request = {
      chatId: currentChatId,
    };
    setWorkflowCreating(true);
    createWorkflow(request, {
      onSuccess: (response) => {
        console.log("Workflow created", response.data);
        setCurrentWorkflow(response.data);
        setOpenWorkFlowDialog(true);
        setWorkflowCreating(false);
      },
      onError: () => {
        setWorkflowCreating(false);
      }
    });
  };

  const closeWorkflow = () => {
    setOpenWorkFlowDialog(false);
  };

  const onSheetUpload = (request: any) => {
    const newConnectionId = v4();
    request.connectionId = newConnectionId;
    uploadSheet(request, {
      onSuccess: (response) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set("connectionId", newConnectionId);
        navigate({
          pathname: location.pathname,
          search: searchParams.toString(),
        });
        setCurrentText("");
        setOpenSheetUpload(false);
      },
    });
  };

  const onAddChat = () => {
    const request = {
      chatId: currentChatId,
      question: currentText,
      threadId: currentThreadId,
      connectionId: currentConnection?.id,
    };
    setChats((prevChats: any) => [...prevChats,
      { role: "user", content: [{ type: "text", text: { value: currentText } }] },
      { role: "bot", content: [{ type: "loading" }] },
    ])
    addChat(request, {
      onSuccess: (response) => {
        console.log("Chat added", response.data);
        modifyChats({ data: response.data.messages });
        setCurrentText("");
      },
    });
  };

  const handleEnterEvent = (e: any) => {
    if (e.keyCode === 13) {
      onAddChat();
    }
  };

  const handleSubmitEnterEvent = (e: any) => {
    if (e.keyCode === 13) {
      onSubmit();
    }
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (chatRef.current) {
        const scrollOptions: ScrollToOptions = {
          top: chatRef.current.scrollHeight,
          behavior: "smooth",
        };
        chatRef.current.scrollTo(scrollOptions);
      }
    }, 100);
  };

  useEffect(() => {
    hljs.highlightAll();
    scrollToBottom();
  }, [chats]);

  return (
    <div className={classes.root}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingChatDetails}
      >
        <CircularProgress sx={{ color: "#4B0082" }} />
      </Backdrop>
      {connectionId !== "" && chats?.length > 0 && (
        <div className={classes.previewContainer}>
          <Tooltip title="Preview file" placement="bottom">
            <div className={classes.preview} onClick={handlePreviewModal}>
              <img src={FileTypes[currentConnection?.type]?.icon} alt="file" />
              {currentConnection?.displayName}
            </div>
          </Tooltip>
          <div className={classes.rightPreview}>
            <Button
              startIcon={<FileDownloadOutlined />}
              onClick={exportPDF}
              className={classes.rightButton}
            >
              Export
            </Button>
            <Button
              startIcon={
                workflowCreating ? (
                  <l-trio size="20" speed="0.9" color="#4B0082"></l-trio>
                ) : (
                  <AutoAwesomeOutlined />
                )
              }
              onClick={onCreateWorkflow}
              className={classes.rightButton}
              disabled={workflowCreating}
            >
              { workflowCreating? "Creating workflow" : "Create workflow"}
            </Button>
          </div>
        </div>
      )}
      {connectionId === "" ? (
        <div className={classes.welcome}>
          <div className={classes.title}>
            Analyze files, write code, create dashboards, and more
          </div>
          <div className={classes.textbox}>
            {droppedFile && (
              <div className={classes.preview} onClick={handlePreviewModal}>
                <img src={csvIcon} alt="csv" />
                {droppedFile?.name}
              </div>
            )}
            <TextField
              value={currentText}
              onChange={(e) => setCurrentText(e.target.value)}
              onKeyDown={handleSubmitEnterEvent}
              autoComplete="off"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    border: "transparent",
                  },
                  fieldset: {
                    border: "transparent",
                  },
                },
              }}
              placeholder="Tell us what you want to do..."
            />
            <div className={classes.footer}>
              <Button
                startIcon={<AttachFileRounded />}
                className={classes.button}
                onClick={() => setOpenUpload(true)}
                disabled={uploadingFile}
              >
                File
              </Button>

              <Button
                startIcon={<LinkRounded />}
                className={classes.button}
                onClick={() => setOpenSheetUpload(true)}
                disabled={uploadingFile}
              >
                Link GSheet
              </Button>

              {uploadingFile ? (
                <CircularProgress
                  size={20}
                  sx={{ color: "#4B0082" }}
                  style={{ marginLeft: "auto" }}
                />
              ) : (
                <IconButton
                  className={classes.rightButton}
                  disabled={!currentText || !droppedFile}
                  onClick={onSubmit}
                >
                  <SendRounded />
                </IconButton>
              )}
            </div>
          </div>
        </div>
      ) : chats?.length > 0 ? (
        <div className={classes.chatParent}>
          <div
            className={classes.chatsContainer}
            ref={chatRef}
            id="chat-container"
          >
            {chats?.map((chat: any) => (
              <div className={classes.chatWrapper}>
                <div className={classes.chat} key={chat?.id}>
                  {chat.role !== "user" ? (
                    chat?.content?.[0]?.type !== "code" && (
                      <img
                        className={classes.logoTile}
                        src={botLogo}
                        alt="Logo"
                        height={24}
                      ></img>
                    )
                  ) : (
                    <Avatar
                      className={classes.logoTile}
                      {...stringAvatar(email)}
                    />
                  )}
                  <div className={classes.content}>
                    {chat?.content?.map((c: any, index: number) => {
                      if (c?.type === "loading") {
                        return (
                          <div className={classes.loading}>
                            <l-dot-wave
                              size="40"
                              speed="1"
                              color="#4B0082"
                            ></l-dot-wave>
                          </div>
                        );
                      } else if (c?.type === "text") {
                        return (
                          <ReactMarkdown
                            key={index}
                            className={classes.markdown}
                            children={c?.text?.value?.replace(
                              /[\u3000-\u303f].*?[\u3000-\u303f]/g,
                              ""
                            )}
                            remarkPlugins={[remarkGfm]}
                            rehypePlugins={[rehypeRaw]}
                          />
                        );
                      } else if (c?.type === "image_file") {
                        return (
                          <img
                            key={index}
                            className={classes.image}
                            src={c?.image_file?.url}
                            alt="graphic"
                            width={"100%"}
                          ></img>
                        );
                      } else if (c?.type === "code") {
                        return (
                          <Accordion className={classes.accordion} key={index}>
                            <AccordionSummary
                              className={classes.accordionSummary}
                              expandIcon={<ExpandMoreRounded />}
                            >
                              Show Code
                            </AccordionSummary>
                            <AccordionDetails>
                              <div key={index} className={classes.markdown}>
                                <pre>
                                  <code>{c?.text?.value}</code>
                                </pre>
                              </div>
                            </AccordionDetails>
                          </Accordion>
                        );
                      }
                    })}
                  </div>
                </div>
                <div className={classes.downloadFiles}>
                  {chat?.files?.map((f: any, index: number) => (
                    <Button
                      key={index}
                      onClick={() => downloadFile(f?.url, f?.displayName)}
                      startIcon={<FileDownloadRounded />}
                      className={classes.button}
                    >
                      {f?.displayName || f?.filename}
                    </Button>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <TextField
            value={currentText}
            onChange={(e) => setCurrentText(e.target.value)}
            onKeyDown={handleEnterEvent}
            autoComplete="off"
            sx={{
              width: "calc(100% - 64px)",
              marginTop: "auto",
              marginLeft: "auto",
              marginRight: "auto",
              padding: "0px 24px 0px 24px",
              background: "#fff",
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#4B0082",
                  borderWidth: 1,
                },
              },
            }}
            placeholder="Type in to ask a question..."
            // ref={inputRef}
            InputProps={{
              disabled: addingChat,
              autoFocus: true,
              style: {
                borderRadius: 32,
                boxShadow: "0px 2px 15px 0px #4B0082 60",
                height: 48,
              },
              endAdornment: (
                <InputAdornment position="end">
                  {addingChat ? (
                    <CircularProgress size={20} sx={{ color: "#4B0082" }} />
                  ) : (
                    <IconButton
                      onClick={() => onAddChat()}
                      className={classes.rightButton}
                    >
                      <SendRounded />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          ></TextField>
        </div>
      ) : (
        <div className={classes.welcome}>
          <div className={classes.title}>
            Analyze files, write code, create dashboards, and more
          </div>
          <div className={classes.textbox}>
            {currentConnection && (
              <Tooltip title="Preview file" placement="top" arrow>
                <div className={classes.preview} onClick={handlePreviewModal}>
                  <img
                    src={FileTypes[currentConnection?.type]?.icon}
                    alt="file"
                  />
                  {currentConnection?.displayName}
                </div>
              </Tooltip>
            )}
            <TextField
              value={currentText}
              onChange={(e) => setCurrentText(e.target.value)}
              onKeyDown={handleEnterEvent}
              autoComplete="off"
              sx={{
                width: "100%",
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused fieldset": {
                    border: "transparent",
                  },
                  fieldset: {
                    border: "transparent",
                  },
                },
              }}
              placeholder="Tell us what you want to do..."
            />
            <div className={classes.footer}>
              {addingChat ? (
                <CircularProgress
                  size={20}
                  sx={{ color: "#4B0082" }}
                  style={{ marginLeft: "auto" }}
                />
              ) : (
                <IconButton
                  className={classes.rightButton}
                  disabled={!currentText}
                  onClick={onAddChat}
                >
                  <SendRounded />
                </IconButton>
              )}
            </div>
          </div>
        </div>
      )}
      <Upload
        openUpload={openUpload}
        setUpload={setOpenUpload}
        onFileDrop={onFileDrop}
      />
      <Dialog
        open={isPreviewModalOpen}
        onClose={handlePreviewModal}
        className={classes.dialog}
        classes={{ paper: classes.paper }}
      >
        <IconButton
          style={{ position: "absolute", top: 4, right: 4 }}
          onClick={() => handlePreviewModal()}
        >
          <CloseRounded style={{ fontSize: "16px" }} />
        </IconButton>
        <FileViewer
          email={email}
          currentConnection={currentConnection}
          droppedFile={droppedFile}
        />
      </Dialog>
      <SheetUpload
        openUpload={openSheetUpload}
        setUpload={setOpenSheetUpload}
        uploadFile={onSheetUpload}
        uploadingFile={uploadingSheet}
      />
      <WorkflowEdit
        viewOnly={true}
        openWorkFlowDialog={openWorkFlowDialog}
        closeWorkflow={closeWorkflow}
        currentWorkflow={currentWorkflow}
      />
    </div>
  );
}
