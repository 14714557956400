import React, { useState, useEffect } from "react";
import {
  useDeleteWorkflow,
  useSaveWorkflow,
  useWorkflows,
} from "../api/workflow";
import useQueryParameter from "../utils/util";
import classes from "./Workflow.module.scss";
import {
  AddRounded,
  CloseRounded,
  EditOutlined,
  EditRounded,
  MoreVertRounded,
  PlayCircleOutlineRounded,
  PlusOneRounded,
  RemoveCircleOutline,
  Close
} from "@mui/icons-material";
import {
  Tooltip,
  IconButton,
  Backdrop,
  CircularProgress,
  Dialog,
  TextField,
  Button,
  List,
  ListItem,
  Popover,
} from "@mui/material";
import { v4 } from "uuid";

type WorkflowEditProps = {
  viewOnly?: boolean;
  openWorkFlowDialog: boolean;
  closeWorkflow: () => void;
  currentWorkflow: any;
  onChange?: (index: number, value: string) => void;
  removeStep?: (index: number) => void;
  onAddStep?: () => void;
  onNameChange?: (name: string) => void;
  onSaveWorkflow?: () => void;
};

export const WorkflowEdit = ({
  viewOnly,
  openWorkFlowDialog,
  closeWorkflow,
  currentWorkflow,
  onChange,
  removeStep,
  onAddStep,
  onNameChange,
  onSaveWorkflow,
}: WorkflowEditProps) => {
  const [editedName, setEditedName] = useState(currentWorkflow?.name || '');
  const [editing, setEditing] = useState(false);
  const [shouldSaveWorkflow, setShouldSaveWorkflow] = useState(false);
  
  useEffect(() => {
    setEditedName(currentWorkflow?.name || '');
  }, [currentWorkflow]);

  useEffect(() => {
    if (shouldSaveWorkflow) {
      onSaveWorkflow && onSaveWorkflow();
      setShouldSaveWorkflow(false); // Reset the flag
    }
  }, [shouldSaveWorkflow]);

  const handleNameChange = (name: string) => {
    if (name) {
      setEditedName(name);
    }
  };

  const cancelEditing = () => {
    setEditedName(currentWorkflow?.name || '');
    onNameChange && onNameChange(currentWorkflow?.name || '');
    setEditing(false);
  }

  const handleSaveWorkflow = () => {
    onNameChange && onNameChange(editedName);
    setEditing(false);
    setShouldSaveWorkflow(true); 
  };

  const handleCloseWorkflow = () => {
    cancelEditing();
    closeWorkflow();
  }

  return (
    <Dialog
      open={openWorkFlowDialog}
      onClose={closeWorkflow}
      className={classes.dialog}
      classes={{ paper: classes.paper }}
    >
      <IconButton
        style={{ position: "absolute", top: 4, right: 4 }}
        onClick={() => closeWorkflow()}
      >
        <CloseRounded style={{ fontSize: "16px" }} />
      </IconButton>
      <div className={classes.content}>
        <div className={classes.editing}>
          {editing ? (
            <TextField
              className={classes.title}
              value={editedName}
              onChange={(e) => handleNameChange(e.target.value)}
              size="small"
              sx={{ maxWidth: 200 }}
            />
          ) : (
            <div className={classes.title}>{editedName} workflow</div>
          )}
          {!viewOnly && (
            <Tooltip
              title={editing ? "Cancel" : "Edit workflow name"}
              placement="top"
              arrow
            >
              {!editing ? (
                <IconButton
                  className={classes.chatIcon}
                  onClick={() => setEditing(true)}
                  sx={{ marginLeft: 1}}
                >
                  <EditOutlined sx={{ fontSize: 20 }}/>
                </IconButton>
              ) : (
                <IconButton
                  className={classes.chatIcon}
                  onClick={cancelEditing}
                  sx={{ marginLeft: 1}}
                >
                  <Close sx={{ fontSize: 20 }}/>
                </IconButton>
              )}
              
            </Tooltip>
          )}
        </div>
        {/* {viewOnly && <div className={classes.title}>{editedName} workflow</div>} */}
        <div className={classes.steps}>
          {currentWorkflow?.steps?.map((step: any, index: number) => (
            <div className={classes.step} key={step?.id}>
              <div className={classes.label}>Step {index + 1}</div>
              <TextField
                className={classes.stepDescription}
                value={step?.prompt}
                sx={{ flex: 1 }}
                size="small"
                onChange={(e) => onChange && onChange(index, e.target.value)}
                disabled={viewOnly}
              />
              {!viewOnly && (
                <Tooltip title="Remove step" placement="top">
                  <IconButton
                    className={classes.remove}
                    onClick={() => removeStep && removeStep(index)}
                  >
                    <RemoveCircleOutline
                      sx={{ fontSize: "18px", color: "red" }}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          ))}
        </div>
        {!viewOnly && (
          <Button
            className={classes.addBtn}
            startIcon={<AddRounded />}
            onClick={onAddStep}
          >
            Add Step
          </Button>
        )}
        <div className={classes.footer}>
          {!viewOnly && (
            <Button className={classes.run} onClick={handleSaveWorkflow}>
              Save
            </Button>
          )}
          {!viewOnly ? (
            <Button className={classes.cancel} onClick={handleCloseWorkflow}>
              Cancel
            </Button>
          ) : (
            <Button className={classes.cancel} onClick={handleCloseWorkflow}>
              Close
            </Button>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default function WorkflowView({is}:any) {
  const email = useQueryParameter("email");
  const { data: workflowsDetails, isLoading: loadingWorkflowsDetails } =
    useWorkflows(email);
  const { mutate: saveWorkflow, isLoading: savingWorkflow } =
    useSaveWorkflow(email);
  const { mutate: deleteWorkflow, isLoading: deletingWorkflow } =
    useDeleteWorkflow(email);
  const [workflows, setWorkflows] = useState<any>([]);
  const [openWorkFlowDialog, setOpenWorkFlowDialog] = useState(false);
  const [currentWorkflow, setCurrentWorkflow] = useState<any>(null);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleMoreClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    clickedWorkflow: any
  ) => {
    setAnchorEl(event.currentTarget);
    setCurrentWorkflow(clickedWorkflow);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);

  const openWorkflow = (clickedWorkflow: any) => {
    setCurrentWorkflow(clickedWorkflow);
    setOpenWorkFlowDialog(true);
  };

  const closeWorkflow = () => {
    setOpenWorkFlowDialog(false);
  };

  useEffect(() => {
    if (workflowsDetails?.data) {
      setWorkflows(workflowsDetails.data);
    }
  }, [workflowsDetails]);

  const onNameChange = (name: string) => {
    console.log('Changing name to:', name);
    setCurrentWorkflow({ ...currentWorkflow, name: name });
  }

  const onAddStep = () => {
    const steps = [...currentWorkflow?.steps];
    steps.push({
      id: v4(),
      prompt: "",
    });
    setCurrentWorkflow({ ...currentWorkflow, steps: steps });
  };

  const removeStep = (index: number) => {
    const steps = [...currentWorkflow?.steps];
    steps.splice(index, 1);
    setCurrentWorkflow({ ...currentWorkflow, steps: steps });
  };

  const onChange = (index: number, value: string) => {
    const steps = [...currentWorkflow?.steps];
    steps[index].prompt = value;
    setCurrentWorkflow({ ...currentWorkflow, steps: steps });
  };

  const onSaveWorkflow = () => {
    saveWorkflow(currentWorkflow, {
      onSuccess: () => {
        closeWorkflow();
      },
    });
  };

  const onDeleteWorkflow = () => {
    const req = {
      workflowId: currentWorkflow?.id,
    };
    deleteWorkflow(req);
  };

  return (
    <div className={classes.workflowView}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingWorkflowsDetails || savingWorkflow || deletingWorkflow}
      >
        <CircularProgress sx={{ color: "#4B0082" }} />
      </Backdrop>
      <div className={classes.title}>Workflows</div>
      <div className={classes.subtitle}>
        To run any workflow go to Files tab, click on more and choose the
        workflow you want to run on the file.
      </div>
      <div className={classes.list}>
        {workflows?.map((workflow: any) => (
          <div className={classes.wrapper} key={workflow?.id}>
            <div className={classes.tile}>
              <div className={classes.connectionName}>{workflow?.name}</div>
              <div className={classes.iconGroup}>
                <Tooltip title="Edit workflow" placement="top" arrow>
                  <IconButton
                    className={classes.chatIcon}
                    onClick={() => openWorkflow(workflow)}
                  >
                    <EditOutlined />
                  </IconButton>
                </Tooltip>
                <Tooltip title="More" placement="top" arrow>
                  <IconButton
                    onClick={(e) => handleMoreClick(e, workflow)}
                    className={classes.chatIcon}
                  >
                    <MoreVertRounded />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        ))}
      </div>
      {!loadingWorkflowsDetails && workflows.length === 0 && (
        <div className={classes.noWorkflows}>
          No workflows have been found. To create a new workflow, please
          navigate to any existing chat or start a new chat, and then click on
          the 'Create workflow' button.
        </div>
      )}
      <WorkflowEdit
        openWorkFlowDialog={openWorkFlowDialog}
        closeWorkflow={closeWorkflow}
        currentWorkflow={currentWorkflow}
        onChange={onChange}
        removeStep={removeStep}
        onAddStep={onAddStep}
        onNameChange={onNameChange}
        onSaveWorkflow={onSaveWorkflow}
      />
      <Popover
        id="more-options-workflow"
        open={openPopover}
        anchorEl={anchorEl}
        classes={{ paper: classes.paper }}
        className={classes.popover}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List>
          <ListItem key="workflow" className={classes.options} disablePadding>
            <Button
              className={classes.optionBtn}
              color="error"
              onClick={() => {
                onDeleteWorkflow();
                handleClose();
              }}
            >
              Delete
            </Button>
          </ListItem>
          {/* <ListItem key="regenerate" className={classes.options} disablePadding>
            <Button
              sx={{ color: "#4B0082" }}
              startIcon={<SyncRounded />}
              onClick={onRegenerateMetadata}
            >
              Sync Metadata
            </Button>
          </ListItem> */}
        </List>
      </Popover>
    </div>
  );
}
