import { useMutation, useQuery } from "react-query";
import { queryClient } from "../..";
import { BE_SERVER } from "../utils/constant";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "notistack";

export const useUploadFile = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (file: any) => {
      const token = await getAccessTokenSilently();
      const formData = new FormData();
      formData.append("file", file);
      const res = await fetch(
        `${BE_SERVER}/api/connections/upload?email=${email}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        }
      );

      if (!res.ok) {
        throw new Error("Error while uploading file");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["connections", email]);
        enqueueSnackbar("File uploaded successfully", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Error while uploading file", { variant: "error" });
      },
    }
  );
};

export const useUploadGSheet = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const res = await fetch(
        `${BE_SERVER}/api/connections/upload/sheets?email=${email}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(request),
        }
      );

      if (!res.ok) {
        throw new Error("Error while uploading gsheet");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["connections", email]);
        enqueueSnackbar("Sheet uploaded successfully", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Error while uploading sheet", { variant: "error" });
      },
    }
  );
};

export const useDownloadFile = (filePath: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(
    ["downloadFile", filePath],
    async () => {
      const token = await getAccessTokenSilently();
      const res = await fetch(
        `${BE_SERVER}/api/connections/download/file?email=${email}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ filePath }),
        }
      );

      if (!res.ok) {
        throw new Error("Error while downloading file");
      }

      const blob = await res.blob();
      return blob;
    },
    {
      enabled: false,
    }
  );
};
