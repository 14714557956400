import { useMutation, useQuery } from "react-query";
import { queryClient } from "../..";
import { BE_SERVER } from "../utils/constant";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "notistack";

export const useCreateWorkflow = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const res = await fetch(`${BE_SERVER}/api/workflow?email=${email}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(request),
      });

      if (!res.ok) {
        throw new Error("Error while adding workflow");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["workflows", email]);
        enqueueSnackbar("Workflow created", {
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar("Error while sending request, please try again", {
          variant: "error",
        });
      },
    }
  );
};

export const useSaveWorkflow = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const res = await fetch(`${BE_SERVER}/api/workflow?email=${email}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(request),
      });

      if (!res.ok) {
        throw new Error("Error while saving workflow");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["workflows", email]);
        enqueueSnackbar("Workflow updated", {
          variant: "success",
        });
      },
      onError: () => {
        enqueueSnackbar("Error while sending request, please try again", {
          variant: "error",
        });
      },
    }
  );
};

export const useWorkflows = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["workflows", email], async () => {
    const token = await getAccessTokenSilently();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const res = await fetch(`${BE_SERVER}/api/workflow?email=${email}`, {
      headers: headers,
    });

    if (!res.ok) {
      throw new Error("Error while fetching workflows details");
    }

    return res.json();
  });
};

export const useRunWorkflow = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const res = await fetch(`${BE_SERVER}/api/workflow/run?email=${email}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(request),
      });

      if (!res.ok) {
        throw new Error("Error while running workflow");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        enqueueSnackbar("Workflow run completed", { variant: "success" });
        queryClient.invalidateQueries(["chatHistory", email]);
      },
      onError: () => {
        enqueueSnackbar("Error while sending request, please try again", {
          variant: "error",
        });
      },
    }
  );
};

export const useDeleteWorkflow = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const res = await fetch(`${BE_SERVER}/api/workflow?email=${email}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(request),
      });

      if (!res.ok) {
        throw new Error("Error while deleting workflow");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["workflows", email]);
      },
      onError: () => {
        enqueueSnackbar("Error while sending request, please try again", {
          variant: "error",
        });
      },
    }
  );
};
