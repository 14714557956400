import csv from "../../icons/csv.svg";
import xlsx from "../../icons/excel.svg";
import sheets from "../../icons/sheets.svg";
import pdf from "../../icons/pdf.svg";

export const BE_SERVER = "https://backend.saydata.tech";
// export const STRIPE_PRICING_TABLE_ID="prctbl_1O7EYQSDpgIdxILn6NBs9jnY";
// export const STRIPE_PUBLISHABLE_KEY="pk_live_51NZXAeSDpgIdxILnFUMT09HgBnRIOtMXwef82z1OEG6SLRdqNDzBJBxBydAA1E2oHDNMbYBadOl9ZvmtVIFkBUU100w9o0vyBX";
// export const BE_SERVER = "http://localhost:8000";
// export const STRIPE_PRICING_TABLE_ID = "prctbl_1NlrB7SDpgIdxILnsZgvdvHv";
// export const STRIPE_PUBLISHABLE_KEY =
//   "pk_test_51NZXAeSDpgIdxILn7fklNE0ANWRv4VaEDidua1CwuJv7ilnVrsAjz7FsYNFMF9Z2YagiaSmM9yafCwCf3mbRNHKA00X1zEq5aG";

export const LOADING_TEXTS = [
  "Gathering data...",
  "Generating query...",
  "Validating results...",
  "Crunching numbers...",
  "Analyzing results...",
  "Tailoring response...",
];

export const TRAINING_LOADING_TEXTS = [
  "Training your model...",
  "Validating datasource...",
  "Cleaning data...",
  "Finetuning the model...",
  "Adding final checks...",
];

export const ENCRYPTION_KEY =
  "c93806d0b35eba427fd8da0dc428fb1c1c52c8af655b2c554b1e0100b73952dc";

type FileType = {
  [key: string]: {
    name: string;
    icon: string;
  };
};
export const FileTypes: FileType = {
  csv: {
    name: "CSV",
    icon: csv,
  },
  xlsx: {
    name: "XLSX",
    icon: xlsx,
  },
  gsheet: {
    name: "Google Sheets",
    icon: sheets,
  },
  pdf: {
    name: "PDF",
    icon: pdf,
  }
};
