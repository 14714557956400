import { useMutation, useQuery } from "react-query";
import { queryClient } from "../..";
import { BE_SERVER } from "../utils/constant";
import { useAuth0 } from "@auth0/auth0-react";
import { useSnackbar } from "notistack";

export const useNewFileChat = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const formData = new FormData();
      formData.append("file", request.file);
      formData.append("chat", request.chat);
      const res = await fetch(`${BE_SERVER}/api/chat/file?email=${email}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!res.ok) {
        throw new Error("Error while uploading file");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["connections", email]);
        queryClient.invalidateQueries(["chatHistory", email]);
        // enqueueSnackbar("File uploaded successfully", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Error while sending request, please try again", {
          variant: "error",
        });
      },
    }
  );
};

export const useAddChat = (email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();
  return useMutation(
    async (request: any) => {
      const token = await getAccessTokenSilently();
      const res = await fetch(`${BE_SERVER}/api/chat/file?email=${email}`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(request),
      });

      if (!res.ok) {
        throw new Error("Error while uploading file");
      }

      return res.json();
    },
    {
      onSuccess: () => {
        //   queryClient.invalidateQueries(["connections", email]);
        queryClient.invalidateQueries(["chatHistory", email]);
        //   enqueueSnackbar("File uploaded successfully", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Error while sending request, please try again", {
          variant: "error",
        });
      },
    }
  );
};

export const useChatDetails = (chatId: string, email: string) => {
  const { getAccessTokenSilently } = useAuth0();
  return useQuery(["chat", chatId], async () => {
    const token = await getAccessTokenSilently();
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const res = await fetch(`${BE_SERVER}/api/chat/file/${chatId}?email=${email}`, {
      headers: headers,
    });

    if (!res.ok) {
      throw new Error("Error while fetching chat details");
    }

    return res.json();
  });
};

export const useChatHistory = (email: string) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(["chatHistory", email], async () => {
      const token = await getAccessTokenSilently();
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const res = await fetch(`${BE_SERVER}/api/chat/history?email=${email}`, {
        headers: headers,
      });
  
      if (!res.ok) {
        throw new Error("Error while fetching chat history");
      }
  
      return res.json();
    });
  };
