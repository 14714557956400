import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import classes from "./SheetUpload.module.scss";
import { CloseRounded } from "@mui/icons-material";
import { useState } from "react";

type SheetUploadProps = {
  openUpload: boolean;
  uploadFile: (request: any) => void;
  uploadingFile: boolean;
  setUpload: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function SheetUpload({
  openUpload,
  setUpload,
  uploadFile,
  uploadingFile,
}: SheetUploadProps) {
  const [sheetUrl, setSheetUrl] = useState<string>("");
  const [name, setName] = useState<string>("");

  const getSheetId = (url: string) => {
    const regex = /\/d\/([a-zA-Z0-9-_]+)/;
    const match = url.match(regex);
    if (match && match.length >= 2) {
      return match[1];
    } else {
      return "";
    }
  };

  const onUpload = () => {
    if (sheetUrl && name) {
      const request = {
        gsheetId: getSheetId(sheetUrl),
        name,
      }
      uploadFile(request);
    }
  }
  return (
    <Dialog
      className={classes.dialog}
      classes={{ paper: classes.paper }}
      open={openUpload}
      onClose={() => setUpload(false)}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={uploadingFile}
      >
        <CircularProgress sx={{ color: "#4B0082" }} />
      </Backdrop>
      <DialogTitle>
        <div className={classes.title}>Google Sheet</div>
        <div className={classes.subtitle}>Make sure your Google Sheet is shared to 'Anyone with the link'</div>
        <IconButton
          style={{ position: "absolute", top: 4, right: 4 }}
          onClick={() => setUpload(false)}
        >
          <CloseRounded style={{ fontSize: "16px" }} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.tile}>
          <div className={classes.title}>Sheet Link</div>
          <TextField
            value={sheetUrl}
            autoComplete="off"
            onChange={(e) => setSheetUrl(e.target.value)}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#4B0082",
                  borderWidth: 1,
                },
              },
            }}
            placeholder="https://docs.google.com..."
            InputProps={{
              style: {
                borderRadius: 4,
                height: 32,
              },
            }}
          ></TextField>
        </div>
        <div className={classes.tile}>
          <div className={classes.title}>Name</div>
          <TextField
            value={name}
            autoComplete="off"
            onChange={(e) => setName(e.target.value)}
            sx={{
              width: "100%",
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#4B0082",
                  borderWidth: 1,
                },
              },
            }}
            placeholder="eg. Sales Report"
            InputProps={{
              style: {
                borderRadius: 4,
                height: 32,
              },
            }}
          ></TextField>
        </div>
      </DialogContent>
      <div className={classes.footer}>
        <Button
          variant="contained"
          className={classes.button}
          size="small"
          onClick={onUpload}
          disabled={uploadingFile || !sheetUrl || !name}
        >
          Upload
        </Button>
      </div>
    </Dialog>
  );
}
