import {
  Collapse,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import classes from "./Sidebar.module.scss";
import logo from "../../icons/named_logo_l.svg";
import { Link, useNavigate } from "react-router-dom";
import {
  AddCommentOutlined,
  AddCommentRounded,
  AddRounded,
  ArrowDownwardRounded,
  AutoAwesomeMosaicOutlined,
  AutoAwesomeMosaicRounded,
  AutoAwesomeOutlined,
  ChatBubbleOutlineRounded,
  HistoryRounded,
  InsertDriveFileOutlined,
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowUp,
  LogoutRounded,
} from "@mui/icons-material";
import clsx from "clsx";
import useQueryParameter from "../utils/util";
import { useAuth0 } from "@auth0/auth0-react";
import { useChatHistory } from "../api/chat";
import { useState } from "react";

export default function Sidebar() {
  const { user, isLoading, logout } = useAuth0();
  const email = user?.email || "";
  const navigate = useNavigate();
  const { data: chatHistory, isLoading: loadingHistory } =
    useChatHistory(email);
  const [historyExpanded, setHistoryExpanded] = useState(false);
  return (
    <div className={classes.sidebar}>
      <Drawer
        variant="permanent"
        anchor="left"
        className={classes.drawer}
        classes={{ paper: classes.paper }}
        sx={{
          "& .MuiDrawer-paper": {
            width: 300,
            transition: "width 0.3s cubic-bezier(0.4, 0, 0.6, 1) 0ms",
            whiteSpace: "nowrap",
          },
        }}
      >
        <List>
          <ListItem>
            <img src={logo} alt="SayData" className={classes.logo} />
          </ListItem>
          <ListItemButton className={classes.newchat}>
            <Link to={`/chat?email=${email}&new=true`} className={classes.link}>
              <ListItemIcon sx={{ minWidth: 0 }}>
                <AddCommentOutlined className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Ask Esco" className={classes.text} />
            </Link>
          </ListItemButton>
          <ListItemButton
            key="history"
            className={classes.item}
            onClick={() => {
              setHistoryExpanded(!historyExpanded);
            }}
          >
            <ListItemIcon style={{ minWidth: 32 }}>
              <ChatBubbleOutlineRounded />
            </ListItemIcon>
            <ListItemText primary="Chats" />
            {historyExpanded ? (
              <KeyboardArrowUpRounded />
            ) : (
              <KeyboardArrowDownRounded />
            )}
          </ListItemButton>
          <Collapse
            in={historyExpanded}
            timeout="auto"
            unmountOnExit
            className={classes.history}
          >
            <List component="div" disablePadding>
              {chatHistory?.data?.map((history: any) => (
                <ListItemButton
                  sx={{ pl: 4 }}
                  key={history?.id}
                  className={classes.item}
                >
                  <Link
                    to={`/chat?chatId=${history?.id}&connectionId=${history?.connectionId}&email=${email}`}
                    className={classes.link}
                    // onClick={() => setSelectedConn(history?.connectionId)}
                  >
                    <ListItemText
                      primary={
                        history?.title?.length > 20
                          ? history?.title?.slice(1, -1).substring(0, 24) +
                            " ..."
                          : history?.title?.slice(1, -1)
                      }
                      className={classes.longText}
                    />
                  </Link>
                </ListItemButton>
              ))}
            </List>
          </Collapse>
          <ListItemButton className={classes.item}>
            <Link to={`/files?email=${email}`} className={classes.link}>
              <ListItemIcon sx={{ minWidth: 20 }}>
                <InsertDriveFileOutlined className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Files" className={classes.text} />
            </Link>
          </ListItemButton>
          <ListItemButton className={classes.item}>
            <Link to="/workflows" className={classes.link}>
              <ListItemIcon sx={{ minWidth: 0 }}>
                <AutoAwesomeMosaicOutlined className={classes.icon} />
              </ListItemIcon>
              <ListItemText primary="Workflows" className={classes.text} />
            </Link>
          </ListItemButton>
          <ListItemButton
            className={classes.footerItem}
            // style={{ position: "absolute", bottom: 40 }}
            onClick={() => {
              logout();
              navigate("https://saydata.tech");
            }}
          >
            <ListItemIcon sx={{ minWidth: 32 }}>
              <LogoutRounded className={classes.icon} />
            </ListItemIcon>
            <ListItemText primary="Logout" className={classes.text} />
          </ListItemButton>
        </List>
      </Drawer>
    </div>
  );
}
