import {
    Backdrop,
    Button,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Divider,
    IconButton,
  } from "@mui/material";
  import { useCallback, useEffect, useRef, useState } from "react";
  import classes from "./Upload.module.scss";
  import { CloseRounded } from "@mui/icons-material";
  import Dropzone from "react-dropzone";
  import { useLocalStorage } from "usehooks-ts";
  
  type UploadProps = {
    openUpload: boolean;
    onFileDrop: (acceptedFiles: any) => void;
    setUpload: React.Dispatch<React.SetStateAction<boolean>>;
  };
  
  export default function Upload({ openUpload, setUpload, onFileDrop }: UploadProps) {
    const inputRef = useRef<HTMLInputElement>(null);
    // const email = useQueryParameter("email");
    const [connectionId, setConnectionId] = useState<string>("");
    // const { data: connectionDetails } = useConnectionById(email, connectionId);
    // const { mutate: uploadFile, isLoading: uploadingFile } = useUploadFile(email);
    const [newInterval, setNewInterval] = useState<any>(null);
    const [activeConnection, setActiveConnection] = useLocalStorage(
      "saydata-active-connection",
      ""
    );
  
    // const onFileDrop = (acceptedFiles: any) => {
    //   if (acceptedFiles?.length > 0) {
    //     uploadFile(acceptedFiles[0], {
    //       onSuccess: (response) => {
    //         setConnectionId(response?.data);
    //         setUpload(false);
    //         const interval = setInterval(() => {
    //           queryClient.invalidateQueries(["connection", response?.data]);
    //         }, 5000);
    //         setNewInterval(interval);
    //       },
    //       onSettled: () => {
    //         if (inputRef.current) {
    //           inputRef.current.value = "";
    //         }
    //       },
    //     });
    //   }
    // };
  
    // useEffect(() => {
    //   if (connectionDetails?.data?.metadataGenerated) {
    //     clearInterval(newInterval);
    //     queryClient.invalidateQueries(["connections", email]);
    //     setActiveConnection(connectionDetails?.data?.id);
    //   }
    // }, [connectionDetails]);
    return (
      <Dialog
        className={classes.dialog}
        classes={{ paper: classes.paper }}
        open={openUpload}
        onClose={() => setUpload(false)}
      >
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={false}
        >
          <CircularProgress sx={{ color: "#4B0082" }} />
        </Backdrop>
        <DialogTitle>
          <div className={classes.title}>Upload file</div>
          <IconButton
            style={{ position: "absolute", top: 4, right: 4 }}
            onClick={() => setUpload(false)}
          >
            <CloseRounded style={{ fontSize: "16px" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Dropzone
            noClick
            accept={{
              "text/csv": [".csv"],
              "application/vnd.ms-excel": [".xlsx"],
              "application/pdf": [".pdf"],
            }}
            onDrop={onFileDrop}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div className={classes.dropzone} {...getRootProps()}>
                  <input {...getInputProps()} ref={inputRef} />
                  <div className={classes.text}>Drag and drop here</div>
                  <Divider className={classes.divider}>OR</Divider>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => inputRef.current?.click()}
                  >
                    Upload from your device
                  </Button>
                </div>
              </section>
            )}
          </Dropzone>
        </DialogContent>
      </Dialog>
    );
  }
  