import logo from "./icons/logo_named_light.png";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Chat from "./components/chat/Chat";
import Sidebar from "./components/sidebar/Sidebar";
import { useEffect } from "react";
import Files from "./components/files/Files";
import Lottie from "lottie-react";
import loadingJson from "./icons/loading.json";
import Login from "./components/login/Login";
import { useCreateUser, useUser } from "./components/api/user";
import { queryClient } from ".";
import WorkflowView from "./components/workflow/WorkflowView";
import { useIsMobile } from "./components/utils/util";
import { Button } from "@mui/material";

function App() {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const { data: dbUser, refetch } = useUser(user?.email || "");
  const { mutate: createUser } = useCreateUser();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (isAuthenticated && user?.email) {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("email", user.email);
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
  }, [user?.email, isAuthenticated, location.pathname]);

  useEffect(() => {
    if (location.pathname === "/" && isAuthenticated) {
      navigate(`/chat?email=${user?.email}`, { replace: true });
    }
  }, [isAuthenticated]);

  // useEffect(() => {
  //   if (user?.email) {
  //     refetch().then(() => {
  //       if (dbUser?.data?.email === "") {
  //         createUser(
  //           {
  //             email: user.email,
  //             firstName: user.given_name,
  //             lastName: user.family_name,
  //           } as any,
  //           {
  //             onSuccess: () => {
  //               refetch();
  //               queryClient.invalidateQueries(["connections", user.email]);
  //             },
  //           }
  //         );
  //       }
  //     });
  //   }
  // }, [user, dbUser]);

  if (isMobile) {
    return (
      <div className="mobile-view">
        <img src={logo} alt="SayData" />
        <p>Mobile version coming soon!</p>
        <p>Meanwhile try logging in using a desktop</p>
        <Button
          variant="contained"
          sx={{ marginTop: 2}}
          href="https://calendar.app.google/Ha83FJLswBiXTKCe6"
          target="_blank"
        >
          Request Demo
        </Button>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          margin: "auto",
          width: 200,
          height: "100vh",
        }}
      >
        <Lottie animationData={loadingJson} loop={true} />
      </div>
    );
  }

  return (
    <>
      <div>
        {isAuthenticated ? (
          <div>
            <Sidebar />
            <Routes>
              <Route path="/chat" element={<Chat />} />
              <Route path="/files" element={<Files />} />
              <Route path="/workflows" element={<WorkflowView />} />
            </Routes>
          </div>
        ) : (
          <Routes>
            <Route path="*" element={<Login />} />
          </Routes>
        )}
      </div>
    </>
  );
}

export default App;
