import React, { useEffect, useMemo, useState } from "react";
import classes from "./Files.module.scss";
import { useDownloadFile } from "../api/file";
import { readFile } from "../utils/util";
import {
  Backdrop,
  CircularProgress,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const FileViewer = ({ email, currentConnection, droppedFile }: any) => {
  const [currentFile, setCurrentFile] = useState<any>(null);
  const [sheetsData, setSheetsData] = useState<{ [key: string]: any[] }>({});
  const [currentSheetName, setCurrentSheetName] = useState<string>("");
  const {
    data: blob,
    isLoading: downloadingFile,
    isSuccess,
    refetch: refetchFile,
  } = useDownloadFile(currentConnection?.host || "", email);

  const objectUrl = useMemo(() => {
    if (currentFile) {
      const url = window.URL.createObjectURL(currentFile);
      return url;
    }
  }, [currentFile]);

  useEffect(() => {
    if (currentConnection?.host) {
      refetchFile();
    }
  }, [currentConnection]);

  useEffect(() => {
    if (isSuccess && blob) {
      let file;
      if (currentConnection.type === "pdf") {
        file = new File([blob], currentConnection?.displayName, {
          type: "application/pdf",
        });
      } else {
        file = new File([blob], currentConnection?.displayName, {
          type:
            currentConnection.type === "csv"
              ? "text/csv"
              : "application/vnd.ms-excel",
        });
        readFile(file)
          .then((data: { [key: string]: any[] }) => {
            setSheetsData(data);
            const firstSheetName = Object.keys(data)[0];
            setCurrentSheetName(firstSheetName);
          })
          .catch((error) => console.error("Error reading file:", error));
      }
      setCurrentFile(file);
    }
  }, [blob, isSuccess, currentConnection]);

  useEffect(() => {
    if (!droppedFile) return;
    readFile(droppedFile)
        .then((data: { [key: string]: any[] }) => {
          setSheetsData(data);
          const firstSheetName = Object.keys(data)[0];
          setCurrentSheetName(firstSheetName);
        })
        .catch((error) => console.error("Error reading file:", error));
  }, [droppedFile]);

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={downloadingFile}
      >
        <CircularProgress sx={{ color: "#4B0082" }} />
      </Backdrop>
      <DialogTitle>
        <div
          className={classes.title}
          style={{ fontSize: 18, fontWeight: 600 }}
        >
          {currentConnection?.displayName || currentFile?.name || ""}
        </div>
      </DialogTitle>
      <DialogContent>
        {currentFile?.type === "application/pdf" ? (
          <div>
            <iframe
              title={currentConnection?.displayName || currentFile?.name || ""}
              src={objectUrl}
              width="100%"
              height={window.innerHeight * 0.7}
              style={{ border: 'none' }}
            >
              This browser does not support PDFs.
            </iframe>
          </div>
        ) : (
          <div>
            <Select
              labelId="sheet-select-label"
              id="sheet-select"
              value={currentSheetName}
              onChange={(e) => setCurrentSheetName(e.target.value)}
              sx={{
                fontWeight: "bold",
                padding: "10px",
                width: "200px",
                height: "40px",
                margin: "10px 0px",
              }}
            >
              {Object.keys(sheetsData).map((sheetName) => (
                <MenuItem key={sheetName} value={sheetName}>
                  {sheetName}
                </MenuItem>
              ))}
            </Select>
            <div
              className="ag-theme-alpine"
              style={{ height: "60vh", width: "100%" }}
            >
              <AgGridReact
                rowData={sheetsData[currentSheetName]}
                columnDefs={
                  sheetsData[currentSheetName]?.length > 0
                    ? Object.keys(sheetsData[currentSheetName][0]).map(
                        (key) => ({
                          headerName: key,
                          field: key,
                          sortable: true,
                          filter: true,
                        })
                      )
                    : []
                }
                autoSizeStrategy={{
                  type: "fitGridWidth",
                }}
              />
            </div>
          </div>
        )}
      </DialogContent>
    </>
  );
};

export default FileViewer;
