import { useState, useEffect } from "react";
import { useConnection, useDeleteConnection } from "../api/connections";
import useQueryParameter, { StyledSwitch } from "../utils/util";
import classes from "./Files.module.scss";
import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  List,
  ListItem,
  Popover,
  Tooltip,
} from "@mui/material";
import {
  ChatBubbleOutlineRounded,
  CloseRounded,
  DeleteForeverOutlined,
  DeleteRounded,
  MoreHorizRounded,
  MoreVertRounded,
  NoteAddRounded,
  PlayCircleOutlineRounded,
  SyncRounded,
} from "@mui/icons-material";
import { useLocalStorage } from "usehooks-ts";
import { useNavigate } from "react-router-dom";
import { FileTypes } from "../utils/constant";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import FileViewer from "./FileViewer";
import Workflow from "../workflow/Workflow";

export default function Files() {
  const email = useQueryParameter("email");
  const { data: connections, isLoading: loadingConnections } =
    useConnection(email);
  const [fileConnections, setFileConnections] = useState<any>([]);
  const [currentConnection, setCurrentConnection] = useState<any>(null);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isWorkflowDialogOpen, setWorkflowDialog] = useState(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { mutate: deleteConnection } = useDeleteConnection(email);

  const handleMoreClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    clickedConn: any
  ) => {
    setAnchorEl(event.currentTarget);
    setCurrentConnection(clickedConn);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const openPopover = Boolean(anchorEl);

  const openFileChat = (connection: any) => {
    navigate(`/chat?email=${email}&connectionId=${connection?.id}`);
  };

  const handlePreviewModal = (connection?: any) => {
    if (connection) {
      setCurrentConnection(connection);
    }
    setIsPreviewModalOpen((isPreviewModalOpen) => !isPreviewModalOpen);
  };

  const handleWorkflowDialog = () => {
    setWorkflowDialog((isWorkflowDialogOpen) => !isWorkflowDialogOpen);
  };

  const handleDeleteConnection = () => {
    if (currentConnection && currentConnection.id) {
      deleteConnection(currentConnection.id, {
        onSuccess: () => {
          console.log("Connection deleted successfully");
        },
        onError: (error) => {
          console.error("Error while deleting connection", error);
        },
      });
    } else {
      console.error('No connection selected for deletion.');
    }
  };

  useEffect(() => {
    if (connections?.data) {
      setFileConnections(
        connections?.data?.filter(
          (c: any) =>
            c?.type === "csv" ||
            c?.type === "gsheet" ||
            c?.type === "pdf" ||
            c?.type === "xlsx"
        )
      );
    }
  }, [connections]);

  console.log("Current connection: ", currentConnection);

  return (
    <div className={classes.files}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingConnections}
      >
        <CircularProgress sx={{ color: "#4B0082" }} />
      </Backdrop>
      <div className={classes.title}>Files</div>
      <div className={classes.list}>
        {fileConnections?.map((connection: any, index: number) => (
          <div className={classes.wrapper} key={index}>
            <div className={classes.tile}>
              <Tooltip title="Preview file" placement="top" arrow>
                <img
                  className={classes.fileIcon}
                  src={FileTypes[connection.type].icon}
                  alt="icon"
                  width={28}
                  height={28}
                  onClick={() => handlePreviewModal(connection)}
                />
              </Tooltip>
              <div className={classes.connectionName}>
                {connection.displayName}
              </div>
              <div className={classes.iconGroup}>
                <Tooltip title="Start a new chat" placement="top" arrow>
                  <IconButton
                    className={classes.chatIcon}
                    onClick={() => openFileChat(connection)}
                  >
                    <ChatBubbleOutlineRounded />
                  </IconButton>
                </Tooltip>
                <Tooltip title="More" placement="top" arrow>
                  <IconButton
                    onClick={(e) => handleMoreClick(e, connection)}
                    className={classes.chatIcon}
                  >
                    <MoreVertRounded />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Dialog
        open={isPreviewModalOpen}
        onClose={handlePreviewModal}
        className={classes.dialog}
        classes={{ paper: classes.paper }}
      >
        <IconButton
          style={{ position: "absolute", top: 4, right: 4 }}
          onClick={() => handlePreviewModal()}
        >
          <CloseRounded style={{ fontSize: "16px" }} />
        </IconButton>
        <FileViewer email={email} currentConnection={currentConnection} />
      </Dialog>
      <Popover
        id="more-options-connections"
        open={openPopover}
        anchorEl={anchorEl}
        className={classes.popover}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List>
          <ListItem key="workflow" className={classes.options} disablePadding>
            <Button
              className={classes.optionBtn}
              startIcon={<PlayCircleOutlineRounded />}
              onClick={() => {
                handleWorkflowDialog();
                handleClose();
              }}
            >
              Run Workflow
            </Button>
          </ListItem>
          <ListItem key="delete" className={classes.deleteOptions} disablePadding>
            <Button
              className={classes.optionBtn}
              startIcon={<DeleteForeverOutlined />}
              onClick={() => {
                handleDeleteConnection();
                handleClose();
              }}
            >
              Delete
            </Button>
          </ListItem>
          {/* <ListItem key="regenerate" className={classes.options} disablePadding>
            <Button
              sx={{ color: "#4B0082" }}
              startIcon={<SyncRounded />}
              onClick={onRegenerateMetadata}
            >
              Sync Metadata
            </Button>
          </ListItem> */}
        </List>
      </Popover>
      <Dialog
        open={isWorkflowDialogOpen}
        onClose={handleWorkflowDialog}
        className={classes.dialogWorkflow}
        classes={{ paper: classes.paper }}
      >
        {/* <IconButton
          style={{ position: "absolute", top: 4, right: 4 }}
          onClick={() => handleWorkflowDialog()}
        >
          <CloseRounded style={{ fontSize: "16px" }} />
        </IconButton> */}
        <Workflow connectionId={currentConnection?.id} email={email} closeWorkflows={handleWorkflowDialog} />
      </Dialog>
    </div>
  );
}
