import { useNavigate } from "react-router-dom";
import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }: any) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || "";
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || "";
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE || "";

  const navigate = useNavigate();

  const onRedirectCallback = (appState: any) => {
    navigate({ pathname: appState?.targetUrl || window.location.pathname });
  };

  const providerConfig = {
    domain: domain,
    clientId: clientId,
    onRedirectCallback,
    authorizationParams: {
      redirect_uri: window.location.origin,
      audience: audience,
      scope: "openid profile email offline_access",
    },
    useRefreshTokens: true,
    useRefreshTokensFallback: true,
  };

  return <Auth0Provider {...providerConfig}>{children}</Auth0Provider>;
};

export default Auth0ProviderWithHistory;
